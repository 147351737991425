import './Reward.scss';
import React, { useState } from 'react';
import src1 from '../assets/107144812_0.jpg';
import src2 from '../assets/118758081_0.jpg';
import src3 from '../assets/116818363_0.jpg';

function Reward(props) {
  const [buttonAnimation, setButtonAnimation] = useState(false);
  const animationDelay = 2000;
  displayNextButton();

  function nextGame() {
    props.onClose(true);
  }

  function displayNextButton() {
    setTimeout(() => setButtonAnimation(true), animationDelay);
  }

  function rewardDisplay(value) {
    switch (value) {
      case 1:
        return (<div className="reward-content">
          <h1>Première échographie !</h1>
          <p>Il y a quelques mois, Thiên Ân a passé sa première échographie pour que l'on puisse observer en amoureux l'étrange créature qui a élu domicile dans son bidou 🤰</p>
          <p>La bestiole n'était pas de très grande taille, mais s'était déjà rapidement développée comme le montre ce cliché où l'on peut apercevoir une fesse, des pieds et surtout un fémur de qualité! 📏 <b>6,6mm le nonos</b></p>
          <img src={src1} alt="fémur"/>
          <h1>On continue?</h1>
          <p>On refait un petit puzzle pour découvrir comment le bébé a grandi en quelques mois 👶</p>
        </div>)
        break;

      case 2:
        return (<div className="reward-content">
          <h1>Seconde échographie !</h1>
          <p>Deux mois plus tard, le bébé a un peu grandi. Comme sa maman il est tout petit :)</p>
          <p>Le bébé pèse tout juste 380g à moins de 5 mois !</p>
          <p>Cette fois-ci, on vous montre sa petite bouille qui a été difficile à capturer vu que bébé aime bien se cacher dans le ventre de sa maman 😅</p>
          <img src={src2} alt="tête"/>
          <h1>Dernière étape ?</h1>
          <p>Un dernier petit puzzle pour découvrir le sexe du bébé 👶</p>
        </div>)
        break;

      case 3:
        return (<div className="reward-content">
          <h1>Bébé a un sexe !</h1>
          <p>Heureusement pour nous, si bébé aime bien caché sa tête, c'est le contraire pour son sexe. La sage-femme l'a trouvé rapidement !</p>
          <p>Si vous avez l'oeil, avec cette photo vous pouvez essayer de deviner le sexe du bébé 🕵️‍♂️</p>
          <img src={src3} alt="sexe"/>
          <h1>Et c'est..</h1>
          <p>Un petit garçon ! 🙍‍♂️</p>
        </div>)
        break;

      default:
        return (<div></div>)
        break;
    }
  }

  return <section id="reward" className="content">
    {rewardDisplay(props.conf.stepNb)}
    {props.next && <div className="menu"><button className={`full ${!buttonAnimation ? 'hidden' : 'animated'}`} onClick={() => nextGame()}>On continue !</button></div>}
  </section>;
}

export default Reward;