import './Stepper.scss';
import React from 'react';

function Stepper(props) {

  /**
   * Return tooltip string to indicate that current step must be finished to go to next one
   */
  function getTitle(nb) {
    const string = "Terminer le casse-tête pour aller sur l'étape suivante :)"
    return nb > props.step ? string : "";
  }

  return <section id="header-stepper" style={{"--step": props.step}}>
    {Array.from({length: props.length}).map((dot, i) =>
      <span key={`row-${i}`} className={`dot ${props.step == i ? 'active' : ''}`} title={getTitle(i)}>
        {++i}
      </span>
    )}
  </section>;
}

export default Stepper;