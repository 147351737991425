import './Help.scss';
import { useState } from 'react';
import src1 from '../assets/tuto_001.gif';

function Help(props) {
  const [dirty, setDirty] = useState(false);

  function close() {
    setDirty(true);
    props.onClose();
  }

  return <section id="help-modal" className={!props.active ? 'hidden' : undefined}>
    <div className="overlay-background" onClick={() => close()}></div>
    <div className="overlay-content">
      <h1>Coucou les copinoux!</h1>
      <p>On veut vous donner pleins d'info sur notre bébé 👶</p>
      <p>Mais ça se mérite d'avoir des nouvelles aussi croustillantes!</p>
      <h2 style={{color: 'pink'}}>C'EST QUOI LE BUT DU JEU ?</h2>
      <p>Pour qu'on vous parle un peu de notre bébé il faut faire disparaître toutes les cases sombres.</p>
      <p>Une fois que l'on voit parfaitement les pieds roses et bleus, on vous racontera pleins de choses 🍼</p>
      <h2 style={{color: 'rgb(99, 230, 240)'}}>COMMENT ON JOUE?</h2>
      <p>Lorsque l'on clique sur une case, elle et ses quatre voisines sont inversées (sombre-clair) comme on peut voir sur le gif en dessous.</p>
      <p className="img"><img src={src1} alt="tuto image" /></p>
      {dirty ? <p className="tip"><b>ASTUCE</b><i>Cliquer sur les noms en haut de la page pour voir la solution :)</i></p> : undefined}
      <p className="play-button"><button className="full" onClick={() => close()}>{dirty ? 'REPRENDRE' : 'JOUER'}</button></p>
    </div>
  </section>
}

export default Help;