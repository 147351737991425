import './TileBoard.scss';
import React, { useState } from 'react';
import { isWin, reverse } from '../util.js';

function TileBoard(props) {
  const [lastClick, setLastClick] = useState([0, 0]);

  /**
   * Return the size of the current game board
   * @returns size of the game board
   */
  function getSize() {
    return props.gameArray.length;
  }

  /**
   * Return a delay relative to the tile coordinates
   * @param {number} i 
   * @param {number} j 
   * @returns {number}
   */
  function victoryAnimation(i = 0, j = 0) {
    switch(props.victoryAnimation) {
      case 'wave':
        return Math.max(lastClick[0], i) - Math.min(lastClick[0], i)
          + Math.max(lastClick[1], j) - Math.min(lastClick[1], j);
      default:
        return getSize()-j-i;
    }
  }

  /**
   * Reverse a tile and update the board game
   * @param {*} gameTiles 
   * @param {*} i 
   * @param {*} j 
   * @param {*} spread 
   */
  function handleTileClick(gameTiles, i, j, spread = false) {
    setLastClick([i, j]);
    props.onTileClick(reverse(gameTiles, i, j, spread), [i, j]);
  }

  function handleRestartClick() {
    props.onRestartClick(false);
  }

  return <div className={`tile-content content ${isWin(props.gameArray) ? 'fadeout' : !props.ellapsedTime ? 'fadein' : ''}`} >
    <section id="tile-board" style={{"--size": props.gameArray.length}} className={isWin(props.gameArray) ? 'win': ''}>
      {props.gameArray.map((row, i) =>
        <div key={`row-${i}`} className="row">
          {row.map((tile, j) =>
            <div key={`tile-${i * getSize() + j}`}
              className={`tile ${tile ? 'on' : 'off'} ${props.highlight.has(`${i}, ${j}`) ? 'highlight' : ''}`}
              onClick={() => !isWin(props.gameArray) && handleTileClick(props.gameArray, i, j, true)}
              style={{"--delay": `${victoryAnimation(i, j)/10}s`, "--i": `${i}`, "--j": `${j}`}}></div>
          )}
        </div>
      )}
    </section>
    <section id="tile-menu" className="menu">
      <button id="tile-restart" className="full" onClick={() => !isWin(props.gameArray) && handleRestartClick()}>Recommencer</button>
    </section>
  </div>;
}

export default TileBoard;