export const STEPS = [
  {
    title: "first",
    boardSize: 4,
    clicks: new Set([[2, 0], [3, 1]].map(e => e.join(', '))),
    reward: {
      stepNb: 1,
      title: "First reward",
      img: "./icon-big.jpg",
      effects: ["👨‍⚕️", "👩‍⚕️", "🧸", "🎂", "🍰", "🧁", "🧷", "👶", "🍼", "🚼"]
    }
  },
  {
    title: "second",
    boardSize: 5,
    clicks: new Set([[1, 1], [4, 2], [4, 3], [0, 4]].map(e => e.join(', '))),
    reward: {
      stepNb: 2,
      title: "Second reward",
      img: "./icon-big.jpg",
      effects: ["👨‍⚕️", "👩‍⚕️", "🧸", "🎂", "🍰", "🧁", "🧷", "👶", "🍼", "🚼"]
    }
  },
  {
    title: "third",
    boardSize: 6,
    clicks: new Set([[0, 0], [1, 1], [1, 2], [3, 1], [4, 4]].map(e => e.join(', '))),
    reward: {
      stepNb: 3,
      title: "Third reward",
      img: "./icon-big.jpg",
      effects: ["👨‍⚕️", "👩‍⚕️", "🧸", "🎂", "🍰", "🧁", "🧷", "👶", "🍼", "🚼"]
    },
  }
];