import React from 'react';
import './App.scss';
import TileBoard from './TileBoard/TileBoard';
import Stepper from './Stepper/Stepper';
import Reward from './Reward/Reward';
import Help from './Help/Help';
import { isWin, randomInt, reverse } from './util.js';
import { STEPS } from './Stepper/Steps';

class App extends React.Component {
  // const [gameArray, setGameArray] = useState([]);
  defaultSize;
  startArray;

  constructor(props) {
    super(props);
    this.step = 0;
    this.defaultSize = 5;
    [this.startArray, this.solution] = this.buildGame(STEPS[this.step].boardSize, STEPS[this.step].clicks);
    this.state = {
      isHelpDisplayed: true,
      gameArray: this.startArray,
      solution: this.solution,
      highlight: new Set(),
      rewardShow: false,
      ellapsedTime: false
    }
    this.handleTileClick = this.handleTileClick.bind(this);
    this.sendStep = this.sendStep.bind(this);
  }

  /**
   * Generate a squared tile table of the specified size
   * @param {number} size
   * @returns {[boolean[][], Set]}
   */
  buildGame = function(size = this.defaultSize, predefinedGame) {
    let gameTiles = Array.from(
      {length: size},
      () => Array.from({length: size}, () => false)
    );

    // Reset gameTimer
    setTimeout(() => this.setState({ ellapsedTime: true }), 1000)

    if (predefinedGame) {
      return this.startPredefinedGame(gameTiles, predefinedGame);
    } else {
      return this.startRandomGame(gameTiles);
    }
  }

  /**
   * Execute some predefined clicks on the game table to create a challenge
   * @param {*} gameTiles
   * @param {*} clicks
   * @returns
   */
  startPredefinedGame = function(gameTiles, clicks) {
    let tileArray = gameTiles;
    let solution = clicks;

    solution.forEach(step => {
      const steps = step.split(', ').map(e => +e);
      tileArray = reverse(tileArray, steps[0], steps[1], true)
    });

    return [tileArray, solution];
  }

  /**
   * Execute some clicks on some random tiles on the table to create a challenge
   * @param {boolean[][]} gameTiles
   * @returns {[boolean[][], Set]}
   */
  startRandomGame = function(gameTiles) {
    let tileArray = gameTiles;
    const nbOfActions = randomInt(10, 2);
    let solution = new Set();

    for (let i = 0; i < nbOfActions; i++) {
      solution.add(`${randomInt(tileArray.length)}, ${randomInt(tileArray.length)}`);
    }

    solution.forEach(step => {
      const steps = step.split(', ').map(e => +e);
      tileArray = reverse(tileArray, steps[0], steps[1], true)
    });
    return [tileArray, solution];
  }

  startNextGame = function() {
    this.step++;
    [this.startArray, this.solution] = this.buildGame(STEPS[this.step].boardSize, STEPS[this.step].clicks);
    this.setState({
      gameArray: this.startArray,
      solution: this.solution,
      rewardShow: false,
      ellapsedTime: false
    });
  }

  /**
   * Reset game to either a new one or to the actual challenge
   * @param {boolean} newGame
   */
  resetGame = function(newGame = false) {
    if (newGame) {
      [this.startArray, this.solution] = this.buildGame();
    }
    this.setState({
      gameArray: this.startArray,
      solution: this.solution,
      rewardShow: false
    });
  }

  /**
   * Reset the game if it has already been concluded
   * Reverse tile if game is still in progress
   * @param {boolean[][]} tileArray game board array
   * @param {string} tile coordinates
   */
  handleTileClick = function(tileArray, tile) {
    this.setState({ gameArray: tileArray });
    const set = new Set(this.state.solution);
    if (set.has(tile.join(', '))) {
      set.delete(tile.join(', '))
      this.setState({ solution: set });
    } else {
      set.add(tile.join(', '));
      this.setState({ solution: set });
    }

    if (isWin(tileArray)) {
      setTimeout(() => this.setState({ rewardShow: true }), 3000);
    }
  }

  sendStep = function() {
    let newStep = [...this.state.solution].reverse()
      .filter(step => !this.state.highlight.has(step))[0];
    const newHighlight = new Set(this.state.highlight);
    newHighlight.add(newStep);
    this.setState({ highlight: newHighlight});

    setTimeout(() => {
      if (newStep) {
        const reducedHighlight = new Set(this.state.highlight);
        reducedHighlight.delete(newStep);
        this.setState({ highlight: reducedHighlight });
      }
    }, 3000);
  }

  toggleHelpModal = function() {
    this.setState({isHelpDisplayed: !this.state.isHelpDisplayed});
    if (!this.state.isHelpDisplayed) {
      setTimeout(() => {
        document.getElementsByClassName('overlay-content')[0].scrollTop = 0;
      }, 0)
    }
  }

  render() {
    return (
      <div id="reverse-tile-game">
        <section id="header" onClick={this.sendStep}><h1>Thiên Ân + Olivier + bébé</h1></section>
        <button className="help-button outlined" onClick={() => this.toggleHelpModal()}>?</button>
        <Help active={this.state.isHelpDisplayed} onClose={() => this.toggleHelpModal()}></Help>
        <Stepper step={this.step} length={STEPS.length}></Stepper>
        <section id="body">
          { !this.state.rewardShow && <TileBoard gameArray={this.state.gameArray}
            highlight={this.state.highlight}
            onTileClick={this.handleTileClick}
            onRestartClick={() => this.resetGame()}
            victoryAnimation='wave'
            ellapsedTime={this.state.ellapsedTime}></TileBoard> }
          { this.state.rewardShow && <Reward next={STEPS.length > this.step + 1} conf={STEPS[this.step].reward} onClose={() => {this.startNextGame()} }></Reward> }
        </section>
      </div>
    );
  }
}

export default App;
