/**
 * Return a random integer
 * @param {number} a 
 * @param {number} b 
 * @returns {number}
 */
export function randomInt(a = 0, b = 0) {
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  return min + Math.floor(Math.random() * (max - min));
}

/**
 * Reverse the state of a tile and its neighbours
 * @param {boolean[][]} gameTiles 
 * @param {number} i 
 * @param {number} j 
 * @param {boolean} spread 
 * @returns {boolean[][]}
 */
export function reverse(gameTiles, i, j, spread = false) {
  let tileArray = JSON.parse(JSON.stringify(gameTiles)); // Hack to make a copy of values only
  if (
    Math.min(i, j) >= 0 &&
    Math.max(i, j) < tileArray.length
  ) {
    tileArray[i][j] = !tileArray[i][j];

    if (spread) {
      tileArray = reverse(tileArray, i, j-1);
      tileArray = reverse(tileArray, i, j+1);
      tileArray = reverse(tileArray, i-1, j);
      tileArray = reverse(tileArray, i+1, j);
    }
  }
  return tileArray;
}

/**
 * Return true if every cell share the same state
 * @param {boolean[][]} gameTiles 
 * @returns {boolean}
 */
export function isWin(gameTiles) {
  return gameTiles.every(row => row.every(tile => tile)) || gameTiles.every(row => row.every(tile => !tile));
}